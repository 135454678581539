import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
    CenteredQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const FortschreitendeOffenbarung = ({ data }) => (
    <InnerPage title="Fortschreitende Offenbarung" description="">
        <IntroHolder
            title="Fortschreitende Offenbarung"
            subtitle="Seit ewigen Zeiten erhält die Menschheit Führung durch göttliche Offenbarung"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Gott und der Mensch',
                'current-item': 'Fortschreitende Offenbarung',
            }}
        >
            <IntroQuote href="/woran-bahai-glauben/gott-und-der-mensch/fortschreitende-offenbarung/zitate">
                Wie die Sonne die Früchte auf der Erde reifen lässt und allen
                Lebewesen Leben und Wärme gibt, so scheint die Sonne der
                Wahrheit auf alle Seelen, die sie mit dem Feuer göttlicher Liebe
                und Erkenntniskraft erfüllt.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Kein Mensch kann begreifen, wer Gott wirklich ist. Ein
                    Geschöpf ist nicht in der Lage, seinen Schöpfer zu
                    beschreiben oder sein Wesen zu erfassen. Man kann die Welt
                    als Widerspiegelung der Eigenschaften und Zeichen Gottes
                    verstehen.
                </p>
                <h2>Die Gottesoffenbarer</h2>
                <div>
                    <p>
                        Seit ewigen Zeiten ist eines der Zeichen Gottes die
                        Führung des Menschen durch Seine Offenbarer, auch
                        Manifestationen Gottes genannt. Durch die Lehren dieser
                        göttlichen Boten, der Stifter der großen Weltreligionen,
                        wurden die geistigen, sozialen, intellektuellen und
                        ethisch-moralischen Fähigkeiten der Menschheit stets
                        gefördert. Zu den Gottesoffenbarern gehören Abraham,
                        Krishna, Zarathustra, Moses, Buddha, Jesus Christus,
                        Muhammad,{' '}
                        <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/der-bab/">
                            der Báb
                        </Link>{' '}
                        und{' '}
                        <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/bahaullah/">
                            Bahá’u’lláh
                        </Link>
                        .
                    </p>
                </div>
                <div>
                    <p>
                        Als Sprachrohr Gottes haben die Religionsstifter durch
                        ihr Auftreten und Leben einen unvergleichlichen Einfluss
                        auf die Entwicklung des Einzelnen und der Menschheit
                        ausgeübt. Zu allen Zeiten haben sie das Ziel verfolgt,
                        zur geistig-spirituellen und materiellen Entwicklung der
                        Menschheit beizutragen.
                    </p>
                </div>
                <div>
                    <p>
                        Jeder Gottesoffenbarer wirkt als Lichtgestalt für die
                        Welt. Ihr Auftreten ist mit dem Frühlingsbeginn
                        vergleichbar, der die Natur zu neuem Leben erweckt. In
                        der Menschheitsentwicklung hat es immer wieder Zeiten
                        gegeben, in denen die Entwicklung erstarrte,
                        vergleichbar mit dem Winter in der Natur. Die
                        Religionsstifter wirkten wie die Frühlingssonne am
                        geistigen Horizont, deren Kraft weiteren
                        gesellschaftlichen und kulturellen Fortschritt
                        ermöglichte.
                    </p>
                </div>
                <h2>Die gesellschaftsbildende Kraft der Offenbarung</h2>
                <div>
                    <p>
                        Das Erscheinen der Gottesoffenbarer hat zu allen Zeiten
                        sichtbaren Fortschritt in der Welt ermöglicht. Ihre
                        Lehren sprachen immer die grundlegenden Bedürfnisse und
                        Beweggründe der Menschen an und weckten im Einzelnen,
                        aber auch in ganzen Völkern Fähigkeiten, die zur
                        Weiterentwicklung der Gesellschaft in einem nie für
                        möglich gehaltenen Ausmaß beitrugen.
                    </p>
                </div>
                <div>
                    <p>
                        Die heute auf dem gesamten Planeten sichtbaren
                        gesellschaftlichen Veränderungen und Umbrüche weisen
                        darauf hin, dass die Welt aus den Fugen geraten ist und
                        wir uns inmitten eines Prozesses der Wandlung und
                        Neuordnung in Richtung einer weltumspannenden
                        Gemeinschaft befinden.
                    </p>
                </div>
                <h2>Fortschreitende Offenbarung</h2>
                <div>
                    <p>
                        Die Gottesoffenbarer lassen sich mit Spiegeln
                        vergleichen, die das Licht der Sonne in einer für den
                        Menschen annehmbaren und verständlichen Form
                        widerspiegeln. Menschliches Verstehen und Erfordernisse
                        der Gesellschaft haben sich im Laufe der Geschichte
                        stetig weiterentwickelt. Daher gibt es in den Religionen
                        neben den ewigen Grundwahrheiten unterschiedliche
                        soziale Lehren. Jede Religion bringt der Menschheit neue
                        Impulse und fördert ihre weitere Entwicklung.
                        Bahá’u’lláh schreibt:
                    </p>
                </div>
                <CenteredQuote referenceNumber="2">
                    Jeder Prophet, den der allmächtige, unvergleichliche
                    Schöpfer zu den Völkern der Erde zu senden beschloss, war
                    mit einer Botschaft betraut und in einer Weise zu handeln
                    beauftragt, wie sie den Erfordernissen des Zeitalters, in
                    dem Er erschien, am besten entsprach.
                </CenteredQuote>
                <div>
                    <p>
                        Da die Weiterentwicklung der Menschheit kein Ende hat,
                        wird es immer wieder Gottesoffenbarer geben, deren
                        Lehren aufeinander aufbauen. Die Bahá’í glauben demnach,
                        dass nach Bahá’u’lláh in großen Abständen auch weitere
                        Gottesoffenbarer erscheinen werden. „Diese geheiligten
                        Spiegel … sind allesamt auf Erden die Vertreter Dessen,
                        der innerster Kern, reinstes Wesen und letztes Ziel des
                        Weltalls ist. Von Ihm geht ihre Erkenntnis und Macht
                        aus, von Ihm leitet sich ihre Souveränität ab.“
                    </p>
                </div>
                <div>
                    <p>
                        Da das Ziel aller göttlichen „Spiegel“ ein und dasselbe
                        ist, sollte zwischen ihnen kein wirklicher Unterschied
                        gemacht werden.
                    </p>
                </div>
                <CenteredQuote referenceNumber="3">
                    Wenn du mit scharfem Auge hinsiehst, wirst du erkennen, dass
                    sie alle im selben Heiligtum wohnen, sich zum selben Himmel
                    aufschwingen, auf demselben Throne sitzen, dieselbe Sprache
                    sprechen und denselben Glauben verkünden.
                </CenteredQuote>
                <div>
                    <p>
                        Bahá’u’lláh betont die Einheit der Religionen. Demnach
                        stammen alle Religionen vom selben Gott. Wenn alle
                        Religionen als wesentlich eins betrachtet werden, wie
                        lassen sich dann Unterschiede in den sozialen
                        Gepflogenheiten und Lehren der verschiedenen Religionen
                        verstehen?
                    </p>
                </div>
                <div>
                    <p>
                        Vergleichen wir die Religionsstifter mit einem Arzt, der
                        die Nöte der Menschheit zu unterschiedlichen Zeiten
                        erkennt und ein geeignetes Heilmittel anbietet.
                        Bahá’u’lláh schreibt hierzu: „Der allwissende Arzt legt
                        Seinen Finger an den Puls der Menschheit. Er erkennt die
                        Krankheit und verschreibt in Seiner unfehlbaren Weisheit
                        das Heilmittel […]“<sup>4</sup>
                    </p>
                </div>
                <Reference>
                    <p>1. ‘Abdu’l-Bahá, Ansprachen in Paris 5:11</p>
                    <p>2. Bahá’u’lláh, Ährenlese, Kap. 95</p>
                    <p>3. Bahá’u’lláh, Das Buch der Gewissheit 106</p>
                    <p>4. Bahá’u’lláh, Ährenlese 106:1</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default FortschreitendeOffenbarung;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "fortschreitende-offenbarung-feature.jpg" }
        ) {
            ...fluidImage
        }
    }
`;
